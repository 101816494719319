import { Card, Button, Stack, Container } from 'react-bootstrap';
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

import one from '../assets/1.png';
import two from '../assets/2.png';
import three from '../assets/3.png';
import four from '../assets/4.png';
import five from '../assets/5.png';
import six from '../assets/6.png';
import seven from '../assets/7.png';
import eight from '../assets/8.png';

import one_t from '../assets/tamil_brochure/1.png';
import two_t from '../assets/tamil_brochure/2.png';
import three_t from '../assets/tamil_brochure/3.png';
import four_t from '../assets/tamil_brochure/4.png';
import five_t from '../assets/tamil_brochure/5.png';
import six_t from '../assets/tamil_brochure/6.png';
import seven_t from '../assets/tamil_brochure/7.png';
import eight_t from '../assets/tamil_brochure/8.png';

import Brochure from './Brochure';
import * as React from 'react';
import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
import { useState, useEffect, useRef } from 'react';

// const PAGES = [
//     'checkbox-cover',
//     'checkbox-page1',
//     'checkbox-page2',
//     'checkbox-page3'
// ]

const ENGLISH_BROCHURE = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight
];

const TAMIL_BROCHURE = [
    one_t,
    two_t,
    three_t,
    four_t,
    five_t,
    six_t,
    seven_t,
    eight_t
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function BrochureFlip2( ) {
    const firstRender = useRef(true);

    const coverPageRef = useRef(null);

    const nextPage1Ref = useRef(null);
    const nextPage2Ref = useRef(null);
    const nextPage3Ref = useRef(null);

    const prevPage1Ref = useRef(null);
    const prevPage2Ref = useRef(null);
    const prevPage3Ref = useRef(null);

    const pageRefsList = [
        coverPageRef,
        nextPage1Ref,
        prevPage1Ref,
        nextPage2Ref,
        prevPage2Ref,
        nextPage3Ref,
        prevPage3Ref
    ];

    const pageRefs = [
        {'next': coverPageRef, 'prev': null},
        {'next': nextPage1Ref, 'prev': coverPageRef},
        {'next': nextPage2Ref, 'prev': prevPage1Ref},
        {'next': nextPage3Ref, 'prev': prevPage2Ref},
        {'next': null, 'prev': prevPage3Ref}
    ];

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const [pageRef, setPageRef] = React.useState(0);

    const minSwipeDistance = 40

    const onTouchStart = (e) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        // if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
        if(isLeftSwipe){
            handleRight();
        }
        else if(isRightSwipe){
            handleLeft();
        }
    }
    useEffect(() => {
        if(firstRender.current){
            setPageRef(0);
            firstRender.current = false;
            console.log("First page set");
        }
    }, []);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        console.log(pageRef);
    }, [pageRef]);
    const handleRight = () => {
        try{
            const currPageRef = pageRefs[pageRef].next;
            if(currPageRef.current){
                currPageRef.current.click();
            }
            if(pageRef <= 3){
                setPageRef(pageRef+1);
            }
        } catch(error){
            if (!(error instanceof TypeError)) {
                throw error;
            }
        }
    };
    const handleLeft = () => {
        try{
            const currPageRef = pageRefs[pageRef].prev;
            if(currPageRef.current){
                currPageRef.current.click();
            }
            if(pageRef > 0){
                setPageRef(pageRef-1);
            }
        } catch(error){
            if (!(error instanceof TypeError)) {
                throw error;
            }
        }
    };
    return(
        <Container fluid className="d-flex flex-column vh-100" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <Stack gap={1}>
                <div className="p-2">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs variant='fullWidth' textColor="secondary" indicatorColor="secondary" value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="English" {...a11yProps(0)} onClick={() => {setPageRef(0)}}/>
                                <Tab label="தமிழ்" {...a11yProps(1)} onClick={() => {setPageRef(0)}}/>
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Brochure pageRefs={pageRefsList} imgs={ENGLISH_BROCHURE} pageRefSet={setPageRef} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Brochure pageRefs={pageRefsList} imgs={TAMIL_BROCHURE} pageRefSet={setPageRef} />
                        </CustomTabPanel>
                    </Box>
                </div>
                <div className="p-2 d-flex justify-content-center" style={{justifyContent: 'center'}}>
                    <Card style={{ width: '60vw', position: 'relative' }}>
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <Button onClick={handleLeft}><FiArrowLeft className="arrow-icon" /></Button>
                            <Button onClick={handleRight}><FiArrowRight className="arrow-icon" /></Button>
                        </Card.Body>
                    </Card>
                </div>
            </Stack>
        </Container>
    );
}
export default BrochureFlip2;