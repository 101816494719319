// import logo from './logo.svg';
import './App.css';
import logo from './assets/logofile.png';
import Slideshow from './Components/Slideshow';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Image } from 'react-bootstrap';
import RSVP from './Components/RSVP';
import { useState, useRef, useEffect } from 'react';
import { FcHome } from "react-icons/fc";
import { BrowserRouter, useLocation, useSearchParams } from 'react-router-dom';
import BrochureFlip from './Components/BrochureFlip';
import BrochureFlip2 from './Components/BrochureFlip2';
import Orchestra from './Components/Orchestra';
import Livestream from './Components/Livestream';

function AppBody({ pageRoute }) {
  const [page, setPage] = useState(pageRoute);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const listInnerRef = useRef();

  const onScroll = () => {
    // console.log("Scrolling");
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
      }
    }
  };

  useEffect(() => {
    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      console.log("Adding scroll event listener");
      listInnerElement.addEventListener("scroll", onScroll());
    }
  }, []);
  return (
    <Container fluid className="App" data-bs-theme="dark" style={{overflowY: 'auto'}} ref={listInnerRef}>
        <Navbar key={'sm'} expand={'sm'} className="navbar mb-3 navbar-custom" fixed="top" style={{height: "12vh"}}>
          <Container fluid>
            <Navbar.Brand href="#"><img
              src={logo}
              width="70"
              height="70"
              className="d-inline-block align-top"
              alt="Brand logo"
            /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'sm'}`} onClick={() => setShowOffcanvas(true)}/>
            <Navbar.Offcanvas
              show={showOffcanvas}
              onHide={() => setShowOffcanvas(false)}
              id={`offcanvasNavbar-expand-${'sm'}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${'sm'}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'sm'}`}>
                  <Image src={logo} width="70vw" height="70vh" className="d-inline-block align-top" alt="Brand logo" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-2 ms-auto">
                  <Nav.Link href="?page=home" onClick={() => setPage('home')}><FcHome /></Nav.Link>
                  <NavDropdown className='nav-link'
                    title="Arangetram"
                    id={`offcanvasNavbarDropdown-expand-${'sm'}`}
                  >
                    <div style={{top:'10px'}}>
                      <NavDropdown.Item href="?page=rsvp" onClick={() => {setPage('rsvp'); setShowOffcanvas(false);}}>
                        Invitation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="?page=brochure">Brochure</NavDropdown.Item>
                      <NavDropdown.Item href="?page=stream">Livestream</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="?page=orchestration">Orchestration</NavDropdown.Item>
                    </div>
                  </NavDropdown>
                  <Nav.Link href="?page=performances" onClick={() => {setPage('perform'); setShowOffcanvas(false);}}>Performances</Nav.Link>
                  <Nav.Link href="?page=mission" onClick={() => {setPage('mission'); setShowOffcanvas(false);}}>Mission</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <div>
          {page==='home' ? <Slideshow/> : <></>}
          {page==='rsvp' ? 
            <div style={{paddingTop:'12.5vh'}}><RSVP /></div> : <></>}
          {page==='brochure' ? <div style={{paddingTop:'12.5vh'}}><BrochureFlip2 /></div> : <></>}
          {page==='orchestration' ? <div style={{paddingTop:'12.5vh'}}><Orchestra /></div> : <></>}
          {page==='stream' ? <div style={{paddingTop:'12.5vh'}}><Livestream /></div> : <></>}
          {page==='mission' ? <div style={{paddingTop:'12.5vh'}}>Site under development.</div> : <></>}
          {page==='performances' ? <div style={{paddingTop:'12.5vh'}}>This site will later host a list of Shreeya's performances that can be watched.</div> : <></>}
        </div>
        {true==false ? <BrochureFlip /> : <></>}
    </Container>
  );
}
function LocationDisplay() {
  const [searchParams] = useSearchParams();
  console.log("Search params: ", searchParams.toString());
  const params = searchParams.toString().split("=");
  console.log("Params: ", params);
  const location = useLocation();
  console.log("Current location: ", location.pathname);
  if (params[1] !== undefined) {
    return(<AppBody pageRoute={params[1]} />);
  } else {
    return(<AppBody pageRoute={"home"} />);
  }
}
function App() {
  return (<div className="App">
    <BrowserRouter>
      <LocationDisplay  /> 
    </BrowserRouter>
  </div>);
}
export default App;
