// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCl-MDBV1gCrtm-x-Wcx35JATneiYOkJ-o",
  authDomain: "arangetramrsvp-61852.firebaseapp.com",
  projectId: "arangetramrsvp-61852",
  storageBucket: "arangetramrsvp-61852.appspot.com",
  messagingSenderId: "465309190688",
  appId: "1:465309190688:web:e9ee8baf9fd62b1f658a45",
  measurementId: "G-RPSMB58RZ2"
};