import '../App.css';
import { Badge } from 'react-bootstrap';
import { React, useRef } from 'react';
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
function Brochure({ pageRefs, imgs, pageRefSet }) {
    const firstPage = useRef(true);

    const handleFirstPageClick = () => {
        if(firstPage.current){
            pageRefSet(1);
            firstPage.current = false;
        } else {
            pageRefSet(0);
            firstPage.current = true;
        }
    }
    return (
        <>
            <input type="checkbox" id="checkbox-cover"/>
            <input type="checkbox" id="checkbox-page1"/>
            <input type="checkbox" id="checkbox-page2"/>
            <input type="checkbox" id="checkbox-page3"/>
            <div className="book">
                <div className="cover">
                    <label htmlFor="checkbox-cover" onClick={handleFirstPageClick} ref={pageRefs[0]}><img src={imgs[0]} alt="" thumbnail="true" className='coverImg' /></label>
                </div>
                <div className="page" id="page1">
                    <div className="front-page">
                        <img src={imgs[1]} alt=""/>
                        <label style={{visibility:'hidden'}} className="next" htmlFor="checkbox-page1" ref={pageRefs[1]}><Badge pill bg="light" text="dark"><FiArrowRight /></Badge></label>
                    </div>
                    <div className="back-page">
                        <img src={imgs[2]} alt=""/>
                        <label style={{visibility:'hidden'}} className="prev" htmlFor="checkbox-page1" ref={pageRefs[2]}><Badge pill bg="light" text="dark"><FiArrowLeft /></Badge></label>
                    </div>
                </div>
                <div className="page" id="page2">
                    <div className="front-page">
                        <img src={imgs[3]} alt=""/>
                        <label style={{visibility:'hidden'}} className="next" htmlFor="checkbox-page2" ref={pageRefs[3]}><Badge pill bg="light" text="dark"><FiArrowRight /></Badge></label>
                    </div>
                    <div className="back-page">
                        <img src={imgs[4]} alt=""/>
                        <label style={{visibility:'hidden'}} className="prev" htmlFor="checkbox-page2" ref={pageRefs[4]}><Badge pill bg="light" text="dark"><FiArrowLeft /></Badge></label>
                    </div>
                </div>
                <div className="page" id="page3">
                    <div className="front-page">
                        <img src={imgs[5]} alt=""/>
                        <label style={{visibility:'hidden'}} className="next" htmlFor="checkbox-page3" ref={pageRefs[5]}><Badge pill bg="light" text="dark"><FiArrowRight /></Badge></label>
                    </div>
                    <div className="back-page">
                        <img src={imgs[6]} alt=""/>
                        <label style={{visibility:'hidden'}} className="prev" htmlFor="checkbox-page3" ref={pageRefs[6]}><Badge pill bg="light" text="dark"><FiArrowLeft /></Badge></label>
                    </div>
                </div>
                <div className="page" id="page4">
                    <div className="front-page">
                        <img src={imgs[7]} alt=""/>
                        {/* <label className="next" for="checkbox-page3"><Badge pill bg="light" text="dark"><FiArrowRight /></Badge></label> */}
                    </div>
                </div>
                <div className="back-cover"></div>
            </div>
        </>
    );
}

export default Brochure;