import '../App.css';
import { Card, Badge, Container, Row, Col } from 'react-bootstrap';
import singer from "../assets/singer.jpg";
import violinist from "../assets/violinist.jpg";
import flautist from "../assets/flautist.jpg";
import drummer from "../assets/drummer.jpg";
import { GiViolin, GiFlute, GiMicrophone, GiDrum } from "react-icons/gi";

function Orchestra() {
    return (
    <>
        <Container fluid className="vh-100">
            <Row className="card-container">
                <Col xs="auto">
                    <Card style={{ backgroundColor: '#4A3327', position: 'relative'}} className="bio-card">
                        <Card.Img variant="top" src={singer} style={{position: 'relative'}}></Card.Img>
                        <Card.Body>
                            <Card.Title>Sri. Udupi S Srinath <Badge bg="success" style={{position: 'absolute', top:'250px', left:'-3px'}}><GiMicrophone /> Singer</Badge></Card.Title>
                            <hr></hr>
                            <Card.Text className='bio-text'>
                                Sri. UDUPI S SRINATH is the son of Sreemathy. J Malathy & Sri. UDUPI S SREEDHAR, a leading Mridangam & Ghatom Artist. He started his initial training in classical music at the age of eight from Late Sri Alleppey Sreekumar. Later he has undergone training from Sri Parvathipuram H Padmanabha Iyer & Late Sreemathy. Parassala B Ponnammal. Now he is undergoing advanced training from Vidwan Delhi Sounder Rajan. He is a post graduate in Computer Application. He has been regularly giving vocal concerts & also sings for many classical dance performances in India & abroad.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="auto">
                    <Card style={{ backgroundColor: '#4A3327'}} className="bio-card">
                        <Card.Img variant="top" src={violinist} />
                        <Card.Body>
                            <Card.Title>Sreemathy. T.V. Sukanya <Badge bg="danger" style={{position: 'absolute', top:'250px', left:'-3px'}}><GiViolin/> Violinist</Badge></Card.Title>
                            <hr></hr>
                            <Card.Text className='bio-text'>
                            Sreemathy. T.V. Sukanya is a disciple of Sangeetha Kalanidhi Sreemathy. Lalgudi Vijayalakshmi. Academically, she holds bachelors degree in commerce, has completed ICWA inter and also holds M.A., and MPhil in Music. She did her extensive research on the Evolution of Violin and has recently innovated an instrument named Jyaaghoshaa, an acoustic travel and pocket friendly instrument for the Indian classical Violinists.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="auto">
                    <Card style={{ backgroundColor: '#4A3327'}} className="bio-card">
                        <Card.Img variant="top" src={flautist} />
                        <Card.Body>
                            <Card.Title>J.B. Sruthi Sagar <Badge bg="secondary" style={{position: 'absolute', top:'250px', left:'-3px' }}><GiFlute /> Flautist</Badge></Card.Title>
                            <hr></hr>
                            <Card.Text className='bio-text'>
J.B.Sruthi Sagar is an accomplished flautist in the field of Carnatic music. He received his initial training on the flute from Shri. Balasai. He later pursued music under the tutelage of Dr. Sunder (since '99). Learning from a vocalist enabled him to employ the gayaka style in playing the instrument. Sagar is known for his strict adherence to tradition and has carved a niche for himself in the field. He has often been compared to the yesteryear maestro Shri. T.R. Mahalingam (Mali). He has been
performing at major sabhas and festivals across the country. Among several awards that have come his way are the prestigious Ustad Bismillah Khan Yuva Puraskar (2019) from the Sangeeth Natak Academy, Yagnaraman award of excellence (2016) from
Krishna Gana Sabha, Kalki Krishnamurthy memorial award (2015), MS Subbulakshmi fellowship (2014) from Shanmukhananda fine arts, Mumbai. He is an A grade artist of the AIR. Besides a masters in music, he holds a bachelors in mechanical engineering.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="auto">
                    <Card style={{ backgroundColor: '#4A3327'}} className="bio-card">
                        <Card.Img variant="top" src={drummer} />
                        <Card.Body>
                            <Card.Title>Sri. Sreerang CT <Badge bg="primary" style={{position: 'absolute', top:'250px', left:'-3px'}}><GiDrum /> Mridangamist</Badge></Card.Title>
                            <hr></hr>
                            <Card.Text className='bio-text'>
                            Sri. Sreerang CT is presently working as a Thalam instructor at Sree Sankaracharya University of Sanskrit. He has completed his graduation with a master’s degree in Mridangam from Kerala Kalamandalam deemed to be University. He is an All India Radio ‘B grade’ mridangam artist. He has performed with and accompanied by several notable artists, both dancers and musicians in the past 10 years and has also been a part of various dance festivals. Apart from Mridangam, he also showcases expertise in other percussion instruments for live shows and recordings.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default Orchestra;