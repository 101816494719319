import '../App.css';
import { Container, Row } from 'react-bootstrap';
import { useRef } from 'react';

function Livestream() {
    const iframeRef = useRef(null);
    return (
    <>
        <Container fluid className="vh-100">
            <Row className="card-container">
                <iframe ref={iframeRef} src="https://livewebcast.in/shreeya/" style={{height:'100vh',width:'100%'}} title="Shreeya Livestream"></iframe>
            </Row>
        </Container>
    </>
  );
}

export default Livestream;